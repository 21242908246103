import React from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { AnimatePresence, motion } from 'framer-motion'
import Chevron from '@elements/Chevron'
import { strings } from '../../static/strings'
import parse from 'html-react-parser'

const Section = tw.section``
const SectionWrap = tw.div`relative flex flex-col`
const HeadlineWrap = tw.div`px-offset-xs max-w-screen-xl w-full mx-auto relative translate-y-spacingXs-master lg:translate-y-spacingS-master z-10`
const Headline = tw.p`w-full md:w-1/2 2xl:max-w-[750px] xl:w-2/3`
const Wrap = tw(motion.div)`max-w-screen-xl px-offset-xs mx-auto grid sm:grid-cols-2 lg:grid-cols-3 gap-10 w-full`
const Item = tw(motion.div)`relative`
const ImageWrap = styled(motion.div)`
	${tw`w-full h-full overflow-hidden pointer-events-none`}
`
export const Card = styled(motion(Link))`
	${tw`relative block`}
	//hover effect
	.image-wrap:after {
		content: '';
		background-color: #003899;
		opacity: 0;
		transition: opacity ease-in-out 0.25s;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
	&:hover .image-wrap:after {
		opacity: 0.45;
	}
`
const Overline = tw.p`font-futuraBook pb-5 tracking-1 text-sm text-mediumDark`
const Title = tw.p`font-futuraBold text-copyXl md:text-3xl tracking-1 md:tracking-3 pb-4`
const Text = tw.div`font-futuraBook text-sm md:text-copyXl italic tracking-1 pb-4`
const Content = tw.div`p-5 text-dark`
const MoreLink = tw.div`text-primary leading-[21px] tracking-1 md:tracking-2 uppercase text-[14px] pt-5`

const Flex = tw.div`px-5 flex items-center gap-4`
const ShowMore = tw(
	motion.div
)`uppercase  mx-auto relative pt-2 mt-8 text-sm cursor-pointer text-primary tracking-1 md:tracking-2 before:absolute before:w-24 before:bottom-full before:left-0 before:h-px before:bg-light`

const WpAcfPressoverviewBlock = ({ data, locale }) => {
	const { anchor } = data?.attributes
	const lang = locale.substr(0, 2)
	const postsQuery = useStaticQuery(graphql`
		{
			dePosts: allWpPost(sort: { fields: dateGmt, order: DESC }, filter: { locale: { locale: { eq: "de_DE" } } }) {
				edges {
					node {
						featuredImage {
							node {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 1000, quality: 85, formats: [AUTO, WEBP])
									}
								}
								altText
							}
						}
						id
						dateGmt(formatString: "DD. MMM YYYY")
						slug
						uri
						title
						status
						content
						excerpt
						locale {
							locale
						}
					}
				}
			}
			enPosts: allWpPost(sort: { fields: dateGmt, order: DESC }, filter: { locale: { locale: { eq: "en_US" } } }) {
				edges {
					node {
						featuredImage {
							node {
								localFile {
									childImageSharp {
										gatsbyImageData(width: 1000, quality: 85, formats: [AUTO, WEBP])
									}
								}
								altText
							}
						}
						id
						dateGmt(formatString: "DD. MMM YYYY")
						slug
						uri
						title
						status
						content
						excerpt
						locale {
							locale
						}
					}
				}
			}
		}
	`)

	const items = lang === 'de' ? postsQuery?.dePosts?.edges : postsQuery?.enPosts?.edges
	const [showMoreState, setShowMoreState] = React.useState(6)

	return (
		<Section>
			<HeadlineWrap id={anchor !== '' ? anchor : 'pressoverview'}>
				<Headline className='headline-h2'>{parse(strings[lang].press)}</Headline>
			</HeadlineWrap>
			<SectionWrap className='py-16 lg:py-28 bg-superLight'>
				<Wrap transition={{ staggerChildren: 0.5 }}>
					{items.slice(0, showMoreState).map(({ node }, index) => {
						return <PressCard key={`presse-card-${index}`} item={node} index={index} lang={lang} />
					})}
				</Wrap>
				{items?.length > showMoreState && (
					<ShowMore onClick={() => setShowMoreState((current) => 6 + current)}>
						<Flex>
							<Chevron state={'down'} /> {strings[lang].showMore}
						</Flex>
					</ShowMore>
				)}
			</SectionWrap>
		</Section>
	)
}

const PressCard = ({ item, index, lang }) => {
	return (
		<AnimatePresence key={index} exitBeforeEnter>
			<Item custom={index}>
				<Card to={item.uri}>
					<ImageWrap>
						<motion.div className={`block h-full aspect-w-1 aspect-h-1`}>
							<div className='image-wrap'>
								{!item.featuredImage?.node?.localFile && <StaticImage className='w-full h-full !absolute' src='../../images/platzhalter.jpg' alt='' />}
								{item.featuredImage?.node?.localFile && (
									<GatsbyImage className='w-full h-full !absolute' imgClassName='!absolute' image={getImage(item.featuredImage?.node?.localFile)} alt={item.featuredImage?.node?.altText} />
								)}
							</div>
						</motion.div>
					</ImageWrap>
					<Content>
						<Overline>{item.dateGmt}</Overline>
						<Title>{item.title}</Title>
						{item.excerpt && <Text>{parse(item.excerpt)}</Text>}
						{<MoreLink to={item.uri}>{strings[lang].readMore}</MoreLink>}
					</Content>
				</Card>
			</Item>
		</AnimatePresence>
	)
}

export default WpAcfPressoverviewBlock
